import React from "react";
import Navbar from "../components/Navbar";
import { Footer } from "../components/Footer";
const Store = () => {
  return (
    <>

      <section className=" flex items-center justify-center ">
        <Navbar navClass="bg-1D1C1C" />
        <div className="container mt-[140px] m-auto pb-[50px]">
          <h1 className="text-center text-[40px] font-[600]">Shop By Product</h1>
          <div className="lg:grid grid-cols-4 w-full px-[15px] flex-wrap gap-[20px] pb-[90px] mt-[60px] justify-center">
            <div className="">
              <a href="https://amzn.to/40LO619">
                <div className="product-card">
                  <div className="product-card__image">
                    <img
                      src="https://m.media-amazon.com/images/I/614eR+7w2qL._SX466_.jpg"
                      alt="Red Nike Shoes"
                    />
                  </div>
                  <div className="product-card__info">
                    <h2 className="product-card__title font-bold text-lg leading-tight line-clamp-2">Lubriderm Daily Moisture Body + Face Wash, Hydrating Body Wash + Moisturizing Facial Cleanser with Pro-Ceramide & Shea Butter to Gently Cleanse, Light Fragrance & Hypoallergenic, 16 fl. oz</h2>
                    <p className="product-card__description text-sm leading-relaxed line-clamp-2">
                      Cleanse and hydrate your skin from head to toe with Lubriderm Daily Moisture Body + Face Wash. This 2-in-1 full body wash and moisturizing face cleanser from a dermatologist-developed brand gently lifts away dirt, excess oil, and impurities to leave skin feeling clean and soft and healthier-looking.
                      Suitable for all skin types, including sensitive skin, the lightly scented daily cleanser features a gentle formula with pro-ceramide and shea butter to preserve skin's natural moisture barrier.
                      The hypoallergenic, dermatologist-tested body wash is free of sulfates, parabens, phthalates, and dyes, and has never been tested on animals.
                      To use the hydrating face and body wash as part of your daily skincare routine, squeeze onto a washcloth, work into a rich, creamy lather, and then rinse.
                      Follow with your favorite Lubriderm Daily Moisture Lotion to moisturize skin for 24 hours.
                    </p>
                    <div className="product-card__price-row">
                      <span className="product-card__price">$9.99</span>
                      <button className="product-card__btn">Buy Now </button>
                    </div>
                  </div>
                </div>
              </a>
            </div>
            <div className=" lg:mt-[0] sm:mt-[20px] mt-[20px]">
              <a href="https://amzn.to/40HixFG">
                <div className="product-card">
                  <div className="product-card__image">
                    <img
                      src="https://m.media-amazon.com/images/I/61Zk+RGerqL._AC_SX679_.jpg"
                      alt="Red Nike Shoes"
                    />
                  </div>
                  <div className="product-card__info">
                    <h2 className="product-card__title font-bold text-lg leading-tight line-clamp-2"> Cascade Platinum Dishwasher Cleaner, 3 count </h2>
                    <p className="product-card__description text-sm leading-relaxed line-clamp-2">
                      Remove limescale buildup, grease, and odors from your dishwasher with Cascade Dishwasher Cleaner. Cycle after cycle, your dishwasher machine can develop unsightly deposits, buildup, and odors. Cascade Dishwasher Cleaner helps eliminate odors and break through the buildup to reveal a fresher, cleaner dishwasher machine. Cascade Dishwasher Cleaner comes in easy-to-use, single-dose pacs. Simply toss one in and run a cycle to keep your dishwasher machine clean and as fresh as it should be. For best results, use Cascade Dishwasher Cleaner once a month to keep your dishwasher machine sparkling. Cascade Dishwasher Cleaner is recommended by Cascade Detergent. For our ultimate clean, use Cascade Dishwasher Cleaner with Cascade Power Dry Rinse Aid for powerful drying and Cascade Platinum dishwasher detergent. Cascade Dishwasher Cascade is the #1 Recommended Brand in North America*  *More dishwasher brands in North America recommend Cascade vs. any other automatic dishwashing detergent brand, recommendations as part of co-marketing agreements
                    </p>
                    <div className="product-card__price-row">
                      <span className="product-card__price">$6.99</span>
                      <button className="product-card__btn">Buy Now </button>
                    </div>
                  </div>
                </div>
              </a>
            </div>
            <div className=" lg:mt-[0] sm:mt-[20px] mt-[20px]">
              <a href="https://amzn.to/4aYFn0f">
                <div className="product-card">
                  <div className="product-card__image">
                    <img
                      src="https://m.media-amazon.com/images/I/81JUTpIsEWL._AC_SX679_PIbundle-2,TopRight,0,0_SH20_.jpg"
                      alt="Red Nike Shoes"
                    />
                  </div>
                  <div className="product-card__info">
                    <h2 className="product-card__title font-bold text-lg leading-tight line-clamp-2"> Always Discreet Boutique Incontinence Pads, for Bladder Leaks, Extra Heavy Absorbency, Long Length, 20 Count x 2 Packs (40 Count Total) (Packaging May Vary)
                    </h2>
                    <p className="product-card__description text-sm leading-relaxed line-clamp-2">
                      Make worrying about bladder leaks a thing of past. With the Always Discreet Boutique line of adult incontinence pads for women, an overactive bladder won’t get in the way of feeling your best and living life to the fullest. Discover fashionable incontinence pads that fit your body and your lifestyle for protection you can count on and feel comfortable wearing. With Always Discreet Boutique Pads, you get incredible bladder leak protection that feels like your favorite underwear, thanks to the soft, fabric-like material that stays comfortably in place and fits discreetly under your clothing. The pads have a hidden RapidDry core that turns liquid to gel and locks away wetness, so you feel fresh, dry, and secure all day. Plus, side LeakGuards help keep wetness from reaching the edges, so you can leave any concerns about urinary incontinence behind. The chic and feminine pad designs from Always Discreet Boutique let you walk with poise and make bladder leaks no big deal. All you need to do is choose the size and absorbency level that's right for you. Always incontinence pads in smaller sizes work well for light leaks, while larger sizes with greater absorbencies provide more coverage for heavier leaks. Adult incontinence products from Always Discreet are HSA/FSA eligible, so check with your provider for plan coverage.

                    </p>
                    <div className="product-card__price-row">
                      <span className="product-card__price">$29.99</span>
                      <button className="product-card__btn">Buy Now </button>
                    </div>
                  </div>
                </div>
              </a>
            </div>
            <div className=" lg:mt-[0] sm:mt-[20px] mt-[20px]">
              <a href="https://amzn.to/4hNX9pk">
                <div className="product-card">
                  <div className="product-card__image">
                    <img
                      src="https://m.media-amazon.com/images/I/3149neFBzpL._SX300_SY300_QL70_FMwebp_.jpg"
                      alt="Red Nike Shoes"
                    />
                  </div>
                  <div className="product-card__info">
                    <h2 className="product-card__title font-bold text-lg leading-tight line-clamp-2"> Schick Intuition Disposable Razors with Sensitive Skin, 3 Count | Razors Sensitive Skin, Women’s Disposable Razors with a Touch of Aloe, Travel Razor
                    </h2>
                    <p className="product-card__description text-sm leading-relaxed line-clamp-2">
                      No need for shave gel or refills. With a built-in moisturizing bar, Schick Intuition Disposable Razors for Women let you lather and shave in one easy step while also giving you the convenience of a disposable razor. Made with a touch of aloe, this smooth-gliding 4-blade razor hugs the contours of your legs and body for a close, smooth shave. Plus, this sensitive skin razor for women is perfect for first-time shavers, minimizing nicks and cuts.

                    </p>
                    <div className="product-card__price-row">
                      <span className="product-card__price">$10.99</span>
                      <button className="product-card__btn">Buy Now </button>
                    </div>
                  </div>
                </div>
              </a>
            </div>


          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Store;
